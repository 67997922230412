export const gitlabConfig = {
    clientId: window._env_.GITLAB_APP_ID,
    redirectUri: `https://${window.location.host}/git/provider/gitlab/redirect`,
    baseUrl: 'https://gitlab.com',
    authorizationEndpoint: 'https://gitlab.com/oauth/authorize',
    tokenEndpoint: 'https://gitlab.com/oauth/token',
    scopes: "api+write_repository+read_user+openid",
    storage: localStorage, // any Storage object, sessionStorage (default) or localStorage
}

export async function redirectToGitlabAuth(){

    const params = new URLSearchParams({
        client_id: gitlabConfig.clientId,
        response_type: 'code',
        redirect_uri: gitlabConfig.redirectUri,
        scope: gitlabConfig.scopes
    });
    window.location.href = `https://gitlab.com/oauth/authorize?${params.toString()}`.replaceAll("%2B", "+");
}